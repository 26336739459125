'use client';

import type { ComponentPropsWithRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';
import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

import { cn } from '@/lib/utils';

export type InputProps = Omit<ComponentPropsWithRef<'input'>, 'size'> &
    VariantProps<typeof inputVariants> & {
        initialValue?: string;
        label?: string;
        icon?: ReactNode;
        error?: FieldError;
        renderError?: (error?: FieldError) => ReactNode;
    };

const inputVariants = tv(
    {
        variants: {
            size: {
                sm: {
                    input: 'px-2 py-1.5 placeholder:text-sm',
                },
                md: {
                    input: 'px-3 py-2 placeholder:text-sm',
                },
                lg: {
                    input: 'px-4 py-2.5 placeholder:text-base',
                },
            },
            label: {
                true: {
                    input: 'placeholder:text-transparent',
                },
            },
        },
        slots: {
            label: 'pointer-events-none absolute text-sm text-grey-300 transition-transform peer-focus:-translate-y-2 peer-focus:text-[10px] peer-focus:text-black peer-[&:not(:placeholder-shown)]:-translate-y-2 peer-[&:not(:placeholder-shown)]:text-[10px]',
            input: 'peer flex w-full items-center rounded-none border border-grey-200 text-base focus:border-black disabled:border-grey-150 disabled:bg-grey-50 disabled:text-grey-300',
            error: 'p-1 text-sm text-red-500',
        },
        defaultVariants: {
            size: 'md',
        },
        compoundVariants: [
            {
                size: 'sm',
                label: true,
                className: {
                    label: 'left-2 top-1.5',
                    input: 'pb-0 pt-2',
                },
            },
            {
                size: 'md',
                label: true,
                className: {
                    label: 'left-3 top-2.5',
                    input: 'pb-0 pt-3.5',
                },
            },
            {
                size: 'lg',
                label: true,
                className: {
                    label: 'left-4 top-2.5 text-base',
                    input: 'px-4 pb-0 pt-4',
                },
            },
        ],
    },
    {
        responsiveVariants: true,
    },
);

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            children,
            id,
            name,
            placeholder,
            label,
            className,
            size,
            icon,
            error,
            renderError,
            ...props
        },
        ref,
    ) => {
        const { input: inputSlot, label: labelSlot } = inputVariants({
            size,
            label: Boolean(label),
        });

        return (
            <div className={cn('relative grid', className)}>
                <input
                    id={id ?? name}
                    ref={ref}
                    className={inputSlot({
                        className: 'col-span-full row-span-full',
                    })}
                    name={name}
                    placeholder={label ?? placeholder}
                    {...props}
                />
                <span className="col-span-full row-span-full ml-auto mr-2 self-center">
                    {icon}
                </span>
                {label && (
                    <label htmlFor={id ?? name} className={labelSlot()}>
                        {label}
                        {props.required && '*'}
                    </label>
                )}
                {renderError?.(error)}
                {children}
            </div>
        );
    },
);

Input.displayName = 'Input';

export { Input };
