import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type HTMLAttributes } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

import { cn } from '@/lib/utils';

type ParagraphProps = VariantProps<typeof paragraphVariants> &
    HTMLAttributes<HTMLParagraphElement> & {
        asChild?: boolean;
        as?: 'p';
    };

const paragraphVariants = tv(
    {
        variants: {
            size: {
                xxsmall: 'text-xxs',
                xsmall: 'text-xs',
                small: 'text-sm',
                medium: 'text-base',
                large: 'text-lg',
                xlarge: 'text-xl',
            },
            transform: {
                uppercase: 'uppercase',
            },
        },
        compoundVariants: [
            {
                size: 'large',
                transform: 'uppercase',
                className: 'text-base uppercase',
            },
        ],
    },
    {
        responsiveVariants: true,
    },
);

const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
    (
        { asChild, as = 'p', children, className, size, transform, ...props },
        ref,
    ) => {
        if (!children) {
            return null;
        }

        const Comp = asChild ? Slot : as;

        return (
            <Comp
                ref={ref}
                className={cn(
                    paragraphVariants({ size, transform }),
                    className,
                )}
                {...props}>
                {children}
            </Comp>
        );
    },
);

Paragraph.displayName = 'Paragraph';

export { Paragraph, paragraphVariants };
